main > h1 {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#boobs {
  width: calc(100% - 2rem);
  margin: auto;
  display: flex;
  max-width: 20rem;
}

#boobs > button {
  width: 50%;
  margin: auto;
  border-style: solid;
  border-width: 0.25rem;
  height: 10rem;
  cursor: pointer;
}

#boobs > button:first-child {
  border-radius: 5% 0% 50% 50%;
}
#boobs > button:last-child {
  border-radius: 0% 5% 50% 50%;
}

#boobs > button > .nipple {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 1.5rem;
  border-radius: 50%;
}

#boobs > button.selected,
#boobs > button.selected::before {
  filter: brightness(1.2);
}

button.start-stop {
  margin: 0.5rem auto;
  display: flex;
  background: #cfe0d4;
  border-radius: 0.25rem;
  border: solid #128f37 0.1rem;
  font-size: 1.25rem;
  width: 10rem;
  padding: 0;
  line-height: 2rem;
}

button.start-stop > div {
  width: 2rem;
  background: #ffffff63;
  margin-right: 0.5rem;
  font-size: 1rem;
  border-right: solid #128f37 0.1rem;
  box-sizing: border-box;
  line-height: 2rem;
  height: 2rem;
}
