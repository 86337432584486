body {
  font-family: 'Noto Sans', sans-serif;
  margin: 0;
  background: #cfe0d4;
}

html,
body {
  overflow: hidden;
  height: 100%;
  position: relative;
}

button {
  font-family: 'Noto Sans', sans-serif;
  cursor: pointer;
}

button:hover {
  filter: brightness(1.025);
}

main {
  margin: auto;
  width: calc(100% - 1rem);
  max-width: 30rem;
}

button:focus {
  outline: 0;
}

.play-icon:after {
  font-size: 2.25rem;
  line-height: 1.9rem;
  margin-left: 0.1rem;
  content: '\25B8';
  display: inline;
  text-decoration: none;
}

button.icon > .play-icon:after {
  margin-left: -0.35rem;
  font-size: 2rem;
  line-height: 1.2rem;
}

button {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
  margin: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
