.time-table-holder {
  height: calc(100vh - 22rem);
  overflow-y: auto;
  margin-top: 1rem;
}

.time-table-holder > h3 {
  text-align: center;
  margin-bottom: 0.5rem;
}

table.times {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: rgba(18, 143, 55, 0.1);
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  border-radius: 0.25rem;
  font-size: 0.9rem;
}
table.times td,
table.times th {
  padding: 0.25rem 1rem;
}
table.times tr:nth-child(even) {
  background: rgba(18, 143, 55, 0.25);
}
table.times thead {
  border-bottom: 2px solid #128f37;
  background: rgba(18, 143, 55, 0.5);
  display: block;
  border-radius: 0.25rem 0.25rem 0 0;
  outline: none;
}
table.times thead th.expanded {
  max-height: 10rem;
}
table.times thead th:first-child {
  border-left: none;
}
table.times tbody td {
  padding-bottom: 0;
  padding-top: 0;
  padding-right: 0;
  display: flex;
}
table.times thead th {
  outline: none;
  border-radius: 0.25rem 0.25rem 0 0;
  display: flex;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  height: auto;
  max-height: 2.25rem;
  transition: max-height 0.3s ease-out;
  font-weight: bold;
}

table.times tbody tr:last-child td:first-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

th > button {
  min-width: 2.5rem;
  border-top-left-radius: 0.25rem;
  border: solid green 0.1rem;
  border-bottom: none;
  background: #e2ece5;
  margin-right: 0.5rem;
  padding: 0;
  position: relative;
}

.stats > p {
  margin: 0.5rem auto;
}
td > p {
  margin: 0.25rem 0;
}
.icon-buttons {
  display: grid;
  grid-auto-flow: column;
  margin-left: auto;
  margin-right: 0;
  min-height: 1.75rem;
}

.icon-buttons > button.icon {
  margin: 0;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 0.25rem;
  border: solid black 0.1rem;
}

.icon-buttons > button.icon.left {
  border-radius: 0.25rem 0 0 0.25rem;
}
.icon-buttons > button.icon.right {
  border-radius: 0 0.25rem 0.25rem 0;
}

button.icon.resume {
  border: solid #128f37 0.1rem;
  background: #e2ece5;
}
button.icon.delete {
  border: solid #ff6969 0.1rem;
  color: #ff6969;
  background: #ede2e2;
}